<template>
        <div class="row g-0">
          <div class="col">
          </div>
          <div class="col-md-6">
            <section class="main">
            <card class="register-container">
                <div class="register-header">
                    <h2 v-if="isKnotion">Bienvenido(a) {{userInfo.firstName}}</h2>
                    <h2 v-else>Bienvenido a Knotion U</h2>
                    <span>Por favor completa la siguiente información:</span>
                </div>
                <div class="row">
                    <div class="col-md-9">
                        <Textfield :check="true" @change="inputValidation('username')" :error="errors.username" v-model="form.username" placeholder="USUARIO"  v-if="!isKnotion"/>
                        <Textfield :check="true" @change="inputValidation('password')" :error="errors.password" v-model="form.password" placeholder="CONTRASEÑA" v-if="!isKnotion"/>
                        <Textfield :check="true" @change="inputValidation('confirmpassword')" :error="errors.confirmpassword" v-model="form.confirmpassword" placeholder="CONFIRMAR CONTRASEÑA" v-if="!isKnotion"/>
                        <Textfield :check="true" @change="inputValidation('name')" :error="errors.name" v-model="form.name" placeholder="NOMBRE" :disabled="isKnotion"/>
                        <Textfield :check="true" @change="inputValidation('lastname')" :error="errors.lastname" v-model="form.lastname" placeholder="APELLIDO PATERNO" :disabled="isKnotion"/>
                        <Textfield :check="true" @change="inputValidation('lastname2')" :error="errors.lastname2" v-model="form.lastname2" placeholder="APELLIDO MATERNO" :disabled="isKnotion"/>
                        <Textfield :check="!errors.email" @change="inputValidation('email')" :error="errors.email" v-model="form.email" placeholder="EMAIL"/>
                        <span class="email-tooltip"><strong>Importante:</strong> esta dirección de correo electrónico será la llave de ingreso a <em>Coursera</em>, y a través de ella se realizará el envío de múltiple información relacionada a los programas adquiridos, por lo que debe asegurarse tener acceso a su bandeja de entrada.</span>
                        <Textfield :check="!errors.birthdate" @change="inputValidation('birthdate')" :error="errors.birthdate" v-model="form.birthdate" placeholder="FECHA DE NACIMIENTO DD/MM/YYYY"/>
                        <Textfield :check="true" @change="inputValidation('campus')" :error="errors.campus" v-model="form.campus" placeholder="COLEGIO" :disabled="isKnotion"/>
                        <Multidown @select="inputValidation('usertype')" :error="errors.usertype" v-model="form.usertype" :options="optRoles" defaultText="ROL EN EL COLEGIO" modo="single"/>
                        <Multidown @select="inputValidation('levelOfStudy')" :error="errors.levelOfStudy" v-model="form.levelOfStudy" :options="optLevelOfStudy" defaultText="ÚLTIMO GRADO DE ESTUDIOS" modo="single"/>
                        <Multidown @select="inputValidation('topics')" :error="errors.topics" v-model="form.topics" :options="optTopics" defaultText="TEMAS DE INTERÉS" modo="tags"/><br/>
                        <div class="row">
                            <div class="col">
                                <div class="footer-controls">
                                    <Button black class="back-button">Atrás</Button>
                                    <Button @click.prevent="next($event)">Siguiente</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </card>
            </section>
          </div>
        </div>
</template>
<script>
export default {
    data() {
        return {
            form: {
                username: null,
                password: null,
                confirmpassword: null,
                name: null,
                lastname: null,
                lastname2: null,
                email: null,
                usertype: null,
                topics: null,
                birthdate: null,
                campus: null,
                levelOfStudy: null,
            },
            errors: {},
            optTopics: [],
            optRoles: [],
            optLevelOfStudy: [
                {value: 'PREPARATORIA', label: 'Preparatoria'},
                {value: 'LICENCIATURA', label: 'Licenciatura'},
                {value: 'ESPECIALIDAD', label: 'Especialidad'},
                {value: 'MAESTRIA', label: 'Maestría'},
                {value: 'DOCTORADO', label: 'Doctorado'},
                {value: 'NINGUNO', label: 'Ninguno'}
            ],
            checks: {
                birthdate: false,
                email: false
            }
        }
    },
    mounted() {
        this.restartSection();
    },
    computed: {
        isKnotion() {
            return this.$store.getters.isKnotion
        },
        userInfo() {
            return this.$store.getters.getUserInfo
        },
        userAcknowledgment() {
            return this.$store.getters.userAcknowledgment
        }
    },
    methods: {
        async restartSection() {
            if (!this.userAcknowledgment){
                this.$router.replace('/terms');
                return;
            }
            let topics = await this.$kuAPI.getTopics();
            let profileType = this.isKnotion ? 'KNOTION' : 'EXTERNAL'
            let usertypes = await this.$kuAPI.getUserTypes(profileType)
            this.optRoles = usertypes.data.map(type => ({'value' : type.idUserTypeInt, 'label' : type.nameEs}))
            this.optTopics = topics.data.map(topic => ({'value' : topic.idTopicInt, 'label' : topic.nameEs}))

            if(this.isKnotion) { 
                this.form.name = this.userInfo.firstName +' '+ (this.userInfo.secondName || '')
                this.form.lastname = this.userInfo.firstSurname
                this.form.lastname2 = this.userInfo.secondSurname || '-'
                this.form.campus = this.userInfo.campusName
            }
        },
        next() {
            
            if(!this.formValidation()) {
                this.optTopics

                let [day, month, year] = this.form.birthdate.split('/')
                let dataToSave = {
                    "idCourseraUserInt": this.userInfo.idCourseraUserInt,  
                    "idCourseraUser": this.userInfo.idCourseraUser,  
                    "idCityInt": this.userInfo.idCityInt,
                    "idUserTypeInt": this.form.usertype,
                    "idUserKnotion_int": this.userInfo.idUserKnotionInt,   
                    "userName": this.userInfo.userName,
                    "firstName": this.form.name,
                    "secondName": this.form.lastname,
                    "firstSurname": this.userInfo.firstSurname,
                    "secondSurname": this.form.secondSurname,
                    "fullName": this.userInfo.fullName,
                    "email": this.form.email,
                    "birthday": new Date(year, month, day).getTime(),
                    "campusName": this.form.campus,
                    "levelOfStudy": this.form.levelOfStudy,
                    "isWorking": this.userInfo.isWorking,
                    "invoiceRequired": this.userInfo.invoiceRequired,
                    "topics": this.form.topics
                }
                this.$kuAPI.updateUser(dataToSave)
                .then(() => {
                    return this.$kuAPI.acceptTermsAndConditions(this.userAcknowledgment);
                }).then(() =>{
                    return this.$store.dispatch('getUserInfo');
                }).then(() => {
                    this.$router.push('/programs');
                }).catch((err) => {
                    console.error(err)
                });
            }
        },
        isEmpty(str) {
            return (!str || str.length === 0)
        },  
        inputValidation(inputName){
            this.errors[inputName] = false;

            if(this.isEmpty(this.form[inputName])) {
                this.errors[inputName] = 'Este campo es requerido';
            } else {
                const emailRgx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                const bdRegex = /([0-2][0-9]|3[0-1])(\/)(((0)[1-9])|((1)[0-2]))(\/)([0-9][0-9][0-9][0-9])/g;
                const bdate = this.form.birthdate ? this.form.birthdate.split('/').map(dp => parseInt(dp)) : [];
                const someNans = bdate.some(part => isNaN(part));
                switch(inputName) {
                    case 'email': 

                        if(!emailRgx.test(this.form.email)) {
                            this.errors[inputName] = 'Ingresa un email válido ';
                        }
                    break;
                    case 'confirmpassword':
                        if(this.form.password.length && this.form.password != this.form.confirmpassword) {
                            this.errors[inputName] = 'La contraseña no coincide';
                        }
                    break;
                    case 'birthdate':
                        if(!this.form.birthdate.match(bdRegex)) {
                            this.errors[inputName] = 'Formato de la fecha de nacimiento incorrecto (DD/MM/YYYY)';
                        } else {
                            const bdObj = new Date(bdate[2], bdate[1]-1, bdate[0]);
                            const minAgeAgo = new Date();
                            const minAge = 10;
                            minAgeAgo.setFullYear(minAgeAgo.getFullYear() - minAge);
                           
                            if (someNans || isNaN(bdObj))
                                this.errors[inputName] = 'Formato de la fecha de nacimiento incorrecto'
                            else if (bdObj.getTime() > minAgeAgo.getTime()) {
                                this.errors[inputName] = 'No cuentas con la edad mínima para registrarte'
                            }
                        }
                }
            }
            return !this.errors[inputName];
        },
        formValidation() {
            this.errors = {};
            let someError = false;
            const exclude = this.isKnotion ? ['username','password','confirmpassword'] : [];
            for(let key in this.form) {
                if (exclude.indexOf(key) != -1) continue;
                if (!this.inputValidation(key))
                    someError = true;
            }
            return someError;
        }
    }
}
</script>
<style lang="scss" scoped>

.register-container {
    .footer-controls{
        margin: 0;
    }
    .register-header {
        margin-bottom: 20px;
        
        span {
            margin-top: 10px;
        }
    }
    .email-tooltip {
        font-size:12px;
        padding: 0px;
        line-height: 1px;
    }
    .back-button {
        // margin-left: 30px;
    }
    .next {
        button {
            position: relative;
            float: right;
        }
    }
}
</style>